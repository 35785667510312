import { Alert } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Form, Grid, Header, Input } from 'semantic-ui-react'
import { backendUrl } from '../config'
import PasswordStrengthMeter from '../components/PasswordStrMeter'

const ResetPasswordConfirm = () => {
  const [password, setPassword] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)
  const [validated, setValidated] = useState(false)
  const history = useHistory()
  const params = useParams()

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = {
      uid: params.uid,
      token: params.token,
      new_password: password,
    }

    axios
      .post(`${backendUrl}/auth/users/reset_password_confirm/`, data)
      .then((res) => {
        setSubmitted(true)
        setError(false)
      })
      .catch((error) => {
        setError(true)
      })
  }

  const errorAlert = (
    <Alert
      message='Error'
      description={
        <>
          Problem during reset password email send.
          <br />
          Please try again or contact service support for further help.
        </>
      }
      type='error'
      showIcon
    />
  )

  const successAlert = (
    <Alert
      message='Success Tips'
      description={
        <>
          You have successfully updated your password. You can{' '}
          <a href='#' onClick={(e) => history.push('/login')}>
            <strong>login</strong>
          </a>{' '}
          now.
        </>
      }
      type='success'
      showIcon
    />
  )

  const handlePassword = (event) => {
    setPassword(event.target.value)
  }

  return (
    <Grid style={{ height: '100vh', display: 'flex', justifyContent: 'center' }}>
      <Grid.Column style={{ maxWidth: 450, marginTop: 150 }}>
        {error && errorAlert}
        {submitted ? (
          successAlert
        ) : (
          <>
            <Header as='h3' color='blue' textAlign='center'>
              Please input your new password
            </Header>
            <React.Fragment>
              <Form size='large' onSubmit={handleSubmit}>
                <Form.Field>
                  <div style={{ lineHeight: 1, marginBottom: '5px' }}>
                    <span className='d-block form-hint'>
                      To conform with our Strong Password policy, you are required to use a sufficiently strong
                      password. Password must be more than 7 characters.
                    </span>
                  </div>
                  <Input
                    fluid
                    focus
                    required
                    type='password'
                    icon='lock'
                    iconPosition='left'
                    placeholder='Password'
                    name='password'
                    value={password}
                    onChange={handlePassword}
                  />
                  {password.length > 0 && <PasswordStrengthMeter password={password} setValidated={setValidated} />}
                </Form.Field>
                <Form.Field>
                  <Button type='submit' fluid color='primary' disabled={!validated}>
                    Submit
                  </Button>
                </Form.Field>
              </Form>
            </React.Fragment>
          </>
        )}
      </Grid.Column>
    </Grid>
  )
}

export default ResetPasswordConfirm
