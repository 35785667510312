import { Button, Col, Form, Input, message, Radio, Row, Select } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Header, Segment } from 'semantic-ui-react'
import { backendUrl } from '../config'

const { Option } = Select

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 6,
      offset: 8,
    },
  },
}

const UserCreate = () => {
  const [status, setStatus] = useState(true)
  const [role, setRole] = useState('')
  const [form] = Form.useForm()
  const history = useHistory()

  const onFinish = (values) => {
    const data = {
      first_name: values['first_name'],
      last_name: values['last_name'],
      email: values['email'],
      password: values['password'],
      role: role,
      is_active: status,
    }

    axios
      .post(`${backendUrl}/users/`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        form.resetFields()
        message.success('New user created.')
        history.push('/users')
      })
      .catch((error) => {
        if (error.response.data["email"]) message.error(error.response.data.email[0]);
      })
  }

  return (
    <Grid textAlign='center' style={{ minHeight: '100vh', height: '100%', marginTop: 'unset' }}>
      <Grid.Column width={15} className='page-inner-style'>
        <Segment basic>
          <Header className='page-header'>
            <span>New User</span>
          </Header>
          <Form
            {...formItemLayout}
            form={form}
            name='user_create'
            onFinish={onFinish}
            initialValues={{
              is_active: true,
            }}
            scrollToFirstError>

            <Form.Item name='first_name' label='First Name'>
              <Input />
            </Form.Item>

            <Form.Item name='last_name' label='Last Name'>
              <Input />
            </Form.Item>

            <Form.Item
              name='email'
              label='email'
              rules={[
                {
                  required: true,
                  message: 'Please input email',
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item
              name='password'
              label='Password'
              rules={[
                {
                  required: true,
                  message: 'Please input password',
                },
              ]}>
              <Input.Password />
            </Form.Item>

            <Form.Item
              name='role'
              label='Role'
              rules={[
                {
                  required: true,
                  message: 'Please select the user role',
                },
              ]}>
              <Select style={{ width: '100%' }} onChange={(val) => setRole(val)} allowClear>
                <Option key='Admin'>Admin</Option>
                <Option key='Employee'>Employee</Option>
              </Select>
            </Form.Item>

            <Form.Item name='is_active' label='Status'>
              <Radio.Group style={{ width: '100%' }} onChange={(e) => setStatus(e.target.value)}>
                <Row>
                  <Col span={10} offset={1}>
                    <Radio value={true}>Active</Radio>
                  </Col>
                  <Col span={12}>
                    <Radio value={false}>Inactive</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Row gutter={12}>
                <Col className='gutter-row' span={16}>
                  <Button block type='primary' htmlType='submit'>
                    Create
                  </Button>
                </Col>
                <Col className='gutter-row' span={8}>
                  <Button block type='text' onClick={() => history.push('/users')}>
                    Back
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default UserCreate
