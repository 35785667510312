import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  PlusOutlined,
  CopyOutlined
} from '@ant-design/icons'
import { Button, Input, message, Popconfirm, Select, Space, Statistic, Table, Tag, Tooltip } from 'antd'
import axios from 'axios'
import { ExportToCsv } from 'export-to-csv'
import _ from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Grid, Header, Segment } from 'semantic-ui-react'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { backendUrl } from '../config'
import capitalizeFirstLetter from '../utils/capitalize'

const { Search } = Input
const { Option } = Select

const OutboundList = () => {
  const [data, setData] = useState([])
  const [filtered, setFiltered] = useState([])
  const [org, setOrg] = useState([])
  const [mode, setMode] = useState('Pending')
  const [keyword, setKeyword] = useState('')
  const [totalPallets, setTotalPallets] = useState(0)

  const history = useHistory()

  useEffect(() => {
    axios
      .get(`${backendUrl}/outbounds/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        let apts = res.data.map((rec) => {
          return {
            ...rec,
            key: rec.id,
          }
        })
        setData(apts)
        if (mode !== 'All') {
          apts = apts.filter((rec) => rec.status === mode)
        }
        setFiltered(apts)
        setOrg(res.data)
      })
  }, [])

  useEffect(() => {
    let total = 0
    filtered.map((el) => {
      if (el.pallets && el.status === 'Pending') total += el.pallets
    })
    setTotalPallets(total)
  }, [filtered])

  useEffect(() => filterApt(), [keyword])

  const handleDelete = (id) => {
    axios
      .delete(`${backendUrl}/outbounds/${id}/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        if (res.status === 204) {
          message.success('Outbound deleted.')
          const newData = data.filter((item) => item.id !== id)
          setData(newData)
          const newFilt = filtered.filter((item) => item.id !== id)
          setFiltered(newFilt)
          let newOrg = org.filter((item) => item.id !== id)
          setOrg(newOrg)
        }
      })
  }

  const handleShip = (rec) => {
    const formData = new FormData()
    formData.append('fba_id', rec['fba_id'])
    formData.append('arn_number', rec['arn_number'])
    formData.append('pick_done', rec['pick_done'])
    formData.append('pick_up', rec['pick_up'])
    formData.append('customer', rec['customer'])
    formData.append('carrier', rec['carrier'])
    formData.append('pallets', rec['pallets'])
    formData.append('location', rec['location'])
    formData.append('status', 'Shipped')
    formData.append('notes', rec['notes'])
    axios
      .put(`${backendUrl}/outbounds/${rec.id}/`, formData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        message.success('Outbound shipped successfully.')
        const newFiltered = filtered.filter((item) => item.id !== rec.id)
        setFiltered(newFiltered)
        const newData = data.map((item) => {
          if (item.id === rec.id) return {
            ...item,
            status: 'Shipped'
          }
          return item
        })
        setData(newData)
      })
  }

  const options = {
    filename: 'outbounds',
    showLabels: true,
    headers: [
      'FBA ID',
      'ARN Number',
      'Pick Done',
      'Pick Up',
      'Customer',
      'Carrier',
      'Number of Pallets',
      'Status',
      'Notes',
    ],
  }

  const exportCSV = () => {
    if (org.length > 0) {
      let csvdata = []
      for (let i = 0; i < org.length; i++) {
        csvdata.push({
          fba_id: org[i]['fba_id'],
          arn_number: org[i]['arn_number'],
          pick_done: org[i]['pick_done'],
          pick_up: org[i]['pick_up'],
          customer: org[i]['customer'],
          carrier: org[i]['carrier'],
          pallets: org[i]['pallets'],
          status: org[i]['status'],
          notes: org[i]['notes'],
        })
      }
      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(csvdata)
    } else {
      message.warning('No data to export.')
    }
  }

  const columns = [
    {
      title: 'FBA ID',
      dataIndex: 'fba_id',
      key: 'fba_id',
      sorter: (a, b) => (a.fba_id > b.fba_id ? 1 : -1),
      render: (text, record) => {
        return (
          <Space direction="horizontal" style={{ width: '100%', justifyContent: 'space-between' }}>
            <Link to={`/outbound/view/${record.id}`}>{text}</Link>
            <CopyToClipboard text={text}>
              <Tooltip title="Copy FBA ID">
                <a onClick={() => message.success('Successfully Copied.')}>
                  <CopyOutlined className={'action-icon'} />
                </a>
              </Tooltip>
            </CopyToClipboard>
          </Space>
        )
      },
    },
    {
      title: 'ARN Number',
      key: 'arn_number',
      dataIndex: 'arn_number',
      sorter: (a, b) => (a.arn_number > b.arn_number ? 1 : -1),
    },
    {
      title: 'Pick Done',
      dataIndex: 'pick_done',
      key: 'pick_done',
      sorter: (a, b) => moment(a.pick_done || undefined) - moment(b.pick_done || undefined),
      render: (text) => {
        text = text && moment(text).format('MMM DD, YYYY')
        return <span>{text}</span>
      },
    },
    {
      title: 'Pick Up',
      dataIndex: 'pick_up',
      key: 'pick_up',
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.pick_up || undefined) - moment(b.pick_up || undefined),
      render: (text) => {
        text = text && moment(text).format('MMM DD, YYYY')
        return <span>{text}</span>
      },
    },
    {
      title: 'Customer',
      key: 'customer',
      dataIndex: 'customer',
      sorter: (a, b) => (a.customer > b.customer ? 1 : -1),
    },
    {
      title: 'Carrier',
      key: 'carrier',
      dataIndex: 'carrier',
      sorter: (a, b) => (a.carrier > b.carrier ? 1 : -1),
    },
    {
      title: 'Number of Pallets',
      key: 'pallets',
      dataIndex: 'pallets',
      sorter: (a, b) => (a.pallets > b.pallets ? 1 : -1),
    },
    {
      title: 'Location',
      key: 'location',
      dataIndex: 'location',
      sorter: (a, b) => (a.location > b.location ? 1 : -1),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        let color = ''
        if (text === 'Pending') {
          color = 'blue'
        } else if (text === 'Shipped') {
          color = 'green'
        } else if (text === 'Canceled') {
          color = 'orange'
        }
        return (
          text !== '' && (
            <Tag color={color} key={text}>
              {capitalizeFirstLetter(text)}
            </Tag>
          )
        )
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          {record.status === 'Pending' && (
            <Popconfirm
              title='Are you sure to ship this?'
              onConfirm={() => handleShip(record)}
              okText='Yes'
              cancelText='No'>
              <a>
                <Tooltip title='Ship'>
                  <CheckCircleOutlined className={'action-icon'} />
                </Tooltip>
              </a>
            </Popconfirm>
          )}
          <a onClick={() => history.push('/outbound/edit/' + record.key)}>
            <Tooltip title='Edit'>
              <EditOutlined className={'action-icon'} />
            </Tooltip>
          </a>
          <Popconfirm
            title='Are you sure to delete this?'
            onConfirm={() => handleDelete(record.key)}
            okText='Yes'
            cancelText='No'>
            <a>
              <Tooltip title='Delete'>
                <DeleteOutlined className={'action-icon'} />
              </Tooltip>
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const filterKeyword = (rec) => {
    const value = keyword.toLowerCase()
    return (
      rec.customer.toLowerCase().indexOf(value) > -1 ||
      rec.fba_id.toLowerCase().indexOf(value) > -1 ||
      rec.arn_number.toLowerCase().indexOf(value) > -1
    )
  }

  const filterByMode = (value) => {
    let apts = data
    if (value !== 'All') {
      apts = apts.filter((rec) => rec.status === value)
    }
    const newFilt = apts.filter((rec) => {
      return filterKeyword(rec)
    })
    return newFilt
  }

  const onChangeMode = (value) => {
    // const curTime = moment().tz('America/Los_Angeles').format('Y-MM-DD');
    // const curTime = moment().format('Y-MM-DD');
    setMode(value)
    const newFilt = filterByMode(value);
    setFiltered(newFilt)
  }

  const onChangeLocation = (value) => {
    const newFilt = filterByMode(mode);
    setFiltered(newFilt.filter((rec) => !value || rec.location == value))
  }

  const filterApt = () => {
    if (data.length > 0) {
      const debouncedSave = _.debounce(() => {
        // const curTime = moment().tz('America/Los_Angeles').format('Y-MM-DD');
        // const curTime = moment().format('Y-MM-DD');
        const newFilt = data.filter((rec) => {
          if (mode === 'All') {
            return filterKeyword(rec)
          } else {
            return rec.status === mode && filterKeyword(rec)
          }
        })
        setFiltered(newFilt)
      }, 1000)
      debouncedSave()
    }
  }

  return (
    <Grid textAlign='center' style={{ minHeight: '100vh', height: '100%', marginTop: 'unset' }}>
      <Grid.Column width={15} className='page-inner-style'>
        <Segment basic>
          <Header className='page-header'>
            <span>Outbounds</span>
          </Header>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Select defaultValue={mode} style={{ width: 120 }} onChange={onChangeMode}>
                <Option value='All'>All</Option>
                <Option value='Pending'>Pending</Option>
                <Option value='Shipped'>Shipped</Option>
                <Option value='Canceled'>Canceled</Option>
              </Select>
              <Select
                allowClear
                placeholder="Filter by location"
                style={{ width: 160, marginLeft: '10px' }}
                onChange={onChangeLocation}
              >
                <Option value='Ontario'>Ontario</Option>
                <Option value='Reidsville'>Reidsville</Option>
              </Select>
              <Search
                style={{ width: 200, marginLeft: '10px' }}
                placeholder='Search by content'
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'inline-block', marginRight: '50px' }}>
                <Statistic title='Total pending pallets' value={totalPallets} />
              </div>
              <Button style={{ marginRight: '10px' }} onClick={exportCSV} icon={<ExportOutlined />}>
                Export CSV
              </Button>
              <Button type='primary' onClick={() => history.push('/outbound/create')} icon={<PlusOutlined />}>
                New Outbound
              </Button>
            </div>
          </div>
          <Table
            className={'page-table'}
            size='middle'
            columns={columns}
            dataSource={filtered}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 50,
            }}
            showSorterTooltip={false}
          />
        </Segment>
      </Grid.Column>
    </Grid>
  )
}
export default OutboundList
