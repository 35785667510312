import { Alert } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { Button, Form, Grid, Header, Input } from 'semantic-ui-react'
import { backendUrl } from '../config'

export default function ResetPassword() {
  const [email, setEmail] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()

    axios
      .post(`${backendUrl}/auth/users/reset_password/`, {
        email: email,
      })
      .then((res) => {
        setSubmitted(true)
        setError(false)
      })
      .catch((error) => {
        setError(true)
      })
  }

  const errorAlert = (
    <Alert
      message='Error'
      description={
        <span>
          Problem during reset password email send.
          <br />
          Please try again or contact service support for further help.
        </span>
      }
      type='error'
      showIcon
    />
  )

  const successAlert = (
    <Alert
      message='Success'
      description='We sent you an email with reset password link. Please check your email.'
      type='success'
      showIcon
    />
  )

  const handleEmail = (event) => {
    setEmail(event.target.value)
  }

  return (
    <Grid style={{ height: '100vh', display: 'flex', justifyContent: 'center' }}>
      <Grid.Column style={{ maxWidth: 450, marginTop: 150 }}>
        {error && errorAlert}
        {submitted ? (
          successAlert
        ) : (
          <>
            <Header as='h3' color='blue' textAlign='center'>
              Please input your email address registered
            </Header>

            <React.Fragment>
              <Form size='large' onSubmit={handleSubmit}>
                <Form.Field>
                  <Input
                    fluid
                    focus
                    required
                    icon='at'
                    iconPosition='left'
                    placeholder='Email Address'
                    name='email'
                    value={email}
                    onChange={handleEmail}
                  />
                </Form.Field>
                <Form.Field>
                  <Button type='submit' fluid color='primary'>
                    Submit
                  </Button>
                </Form.Field>
              </Form>
            </React.Fragment>
          </>
        )}
      </Grid.Column>
    </Grid>
  )
}
