import {Collapse} from 'antd'
import React from 'react'
import {Link} from 'react-router-dom'
import {Grid, Header, Segment} from 'semantic-ui-react'
import FBARemovalsReport from '../components/reports/FBARemovalsReport'

const {Panel} = Collapse

const Reports = () => {
  return (
    <Grid textAlign='center' style={{minHeight: '100vh', height: '100%', marginTop: 'unset'}}>
      <Grid.Column width={15} className='page-inner-style'>
        <Segment basic>
          <Header className='page-header'>
            <span>Reports</span>
          </Header>
          <Collapse accordion defaultActiveKey='fbaRemovals'>
            <Panel header='FBA Removals' key='fbaRemovals'>
              <FBARemovalsReport />
            </Panel>
            <Panel header='Inbounds' key='inbounds'>
              <p>
                Pending, please use export button in <Link to='/inbounds'>inbounds page</Link>.
              </p>
            </Panel>
            <Panel header='Outbounds' key='outbounds'>
              <p>
                Pending, please use export button in <Link to='/outbounds'>outbounds page</Link>.
              </p>
            </Panel>
          </Collapse>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default Reports
