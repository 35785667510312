import { UploadOutlined } from '@ant-design/icons'
import TextField from '@material-ui/core/TextField'
import { Button, Col, Form, Input, message, Row, Select, Upload } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Header, Segment } from 'semantic-ui-react'
import { backendUrl } from '../config'

const { TextArea } = Input
const { Option } = Select

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 6,
      offset: 8,
    },
  },
}

const OutboundCreate = () => {
  const [status, setStatus] = useState('Pending')
  const [pickDone, setPickDone] = useState(null)
  const [pickUp, setPickUp] = useState(null)
  const [cust, setCust] = useState(null)
  const [customers, setCustomers] = useState([])
  const [fileList, setFileList] = useState([])
  const [uploading, setUploading] = useState(false)
  const [location, setLocation] = useState('');
  const [form] = Form.useForm()
  const history = useHistory()

  useEffect(() => {
    axios
      .get(`${backendUrl}/customers/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        setCustomers(res.data)
      })
  }, [])

  const onFinish = (values) => {
    const data = {
      fba_id: values['fba_id'],
      arn_number: values['arn_number'] || '',
      pick_done: pickDone || '',
      pick_up: pickUp || '',
      customer: cust,
      carrier: values['carrier'] || '',
      pallets: values['pallets'] || '',
      status: status,
      location,
      notes: values['notes'] || '',
      // 'delivery': fileList
    }
    const formData = new FormData()
    formData.append('fba_id', data['fba_id'])
    formData.append('arn_number', data['arn_number'])
    formData.append('pick_done', data['pick_done'])
    formData.append('pick_up', data['pick_up'])
    formData.append('customer', data['customer'])
    formData.append('carrier', data['carrier'])
    formData.append('pallets', data['pallets'])
    formData.append('status', status)
    formData.append('location', data['location'])
    formData.append('notes', data['notes'])
    fileList.forEach((file) => {
      formData.append('delivery_name', file.name)
      formData.append('delivery', file)
    })

    setUploading(true)

    axios
      .post(`${backendUrl}/outbounds/`, formData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        form.resetFields()
        setFileList([])
        setUploading(false)
        message.success('New outbound created.')
        history.push('/outbounds')
      })
      .catch((error) => {
        console.log(error.response)
        if (error.response.data.fba_id) {
          message.error('FBA Shipment already exists.')
        }
        setUploading(false)
      })
  }

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file])
      return false
    },
    fileList,
  }

  return (
    <Grid textAlign='center' style={{ minHeight: '100vh', height: '100%', marginTop: 'unset' }}>
      <Grid.Column width={15} className='page-inner-style'>
        <Segment basic>
          <Header className='page-header'>
            <span>New Outbound</span>
          </Header>
          <Form {...formItemLayout} form={form} name='apt_create' onFinish={onFinish} scrollToFirstError>
            <Form.Item
              name='fba_id'
              label='FBA ID'
              rules={[
                {
                  required: true,
                  message: 'Please input FBA ID!',
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item name='arn_number' label='ARN Number'>
              <Input />
            </Form.Item>

            <Form.Item name='pick_done' label='Pick Done'>
              <TextField
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setPickDone(e.target.value)}
              />
            </Form.Item>

            <Form.Item name='pick_up' label='Pick Up'>
              <TextField
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setPickUp(e.target.value)}
              />
            </Form.Item>

            <Form.Item name='customer' label='Customer' rules={[{ required: true, message: 'Please input Customer!' }]}>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder='Select a customer'
                optionFilterProp='children'
                onChange={(val) => setCust(val)}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {customers.map((cust, index) => (
                  <Option key={index} value={cust.name}>
                    {cust.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name='carrier' label='Carrier'>
              <Input />
            </Form.Item>

            <Form.Item
              name='pallets'
              label='Number of pallets'
              rules={[
                {
                  validator: async (_, pallets) => {
                    if (pallets && isNaN(pallets)) {
                      return Promise.reject(new Error('Should be a valid integer'))
                    }
                  },
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item name='status' label='Status'>
              <Select style={{ width: '100%' }} onChange={(val) => setStatus(val)} allowClear>
                <Option key='Pending'>Pending</Option>
                <Option key='Shipped'>Shipped</Option>
                <Option key='Canceled'>Canceled</Option>
              </Select>
            </Form.Item>

            <Form.Item name='location' label='Location'>
              <Select style={{ width: '100%' }} onChange={(val) => setLocation(val)} allowClear>
                <Option key='Ontario'>Ontario</Option>
                <Option key='Reidsville'>Reidsville</Option>
              </Select>
            </Form.Item>

            <Form.Item name='notes' label='Notes'>
              <TextArea rows={2} />
            </Form.Item>

            <Form.Item name='delivery' label='Proof of Delivery'>
              <Upload {...props}>
                <Button icon={<UploadOutlined />}>Select File</Button>
              </Upload>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Row gutter={12}>
                <Col className='gutter-row' span={16}>
                  <Button block type='primary' htmlType='submit' loading={uploading}>
                    Create
                  </Button>
                </Col>
                <Col className='gutter-row' span={8}>
                  <Button block type='text' onClick={() => history.push('/outbounds')}>
                    Back
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default OutboundCreate
