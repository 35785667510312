import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Container, Menu } from 'semantic-ui-react'
import { logout } from '../store/actions/auth'

class CustomLayout extends React.Component {
  render() {
    const { authenticated, role, logout } = this.props
    console.log(role)
    return (
      <div style={{ background: '#f7f7f7' }}>
        <Menu fixed='top' inverted>
          <Container style={{ justifyContent: 'flex-end' }}>
            {authenticated ? (
              <>
                <Link to='/inbounds'>
                  <Menu.Item header>Inbounds</Menu.Item>
                </Link>
                <Link to='/outbounds'>
                  <Menu.Item header>Outbounds</Menu.Item>
                </Link>
                <Link to='/fba-removals'>
                  <Menu.Item header>FBA removals</Menu.Item>
                </Link>
                {role === 'Admin' && (
                  <Link to='/reports'>
                    <Menu.Item header>Reports</Menu.Item>
                  </Link>
                )}
                <Link to='/customers'>
                  <Menu.Item header>Customers</Menu.Item>
                </Link>
                {role === 'Admin' && (
                  <Link to='/users'>
                    <Menu.Item header>Users</Menu.Item>
                  </Link>
                )}
                <Menu.Item header onClick={() => logout()}>
                  Logout
                </Menu.Item>
              </>
            ) : (
              <React.Fragment>
                <Link to='/login'>
                  <Menu.Item header>Login</Menu.Item>
                </Link>
              </React.Fragment>
            )}
          </Container>
        </Menu>
        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.token !== null,
    role: state.auth.role,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomLayout))
