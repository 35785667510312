import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AptCreate from './containers/AptCreate'
import AptEdit from './containers/AptEdit'
import AptView from './containers/AptView'
import CustCreate from './containers/CustCreate'
import CustEdit from './containers/CustEdit'
import CustomerPage from './containers/Customer'
import CustView from './containers/CustView'
import Dashboard from './containers/Dashboard'
import FBARemovalCreate from './containers/FBARemovalCreate'
import FBARemovalEdit from './containers/FBARemovalEdit'
import FBARemovalReceiptCreate from './containers/FBARemovalReceiptCreate'
import FBARemovalReceiptEdit from './containers/FBARemovalReceiptEdit'
import FBARemovalsList from './containers/FBARemovals'
import FBARemovalView from './containers/FBARemovalView'
import InboundList from './containers/Inbound'
import Login from './containers/Login'
import NotFound from './containers/NotFound'
import OutboundList from './containers/Outbound'
import OutboundCreate from './containers/OutCreate'
import OutboundEdit from './containers/OutEdit'
import OutboundView from './containers/OutView'
import Reports from './containers/Reports'
import ResetPassword from './containers/ResetPassword'
import ResetPasswordConfirm from './containers/ResetPasswordConfirm'
import UserCreate from './containers/UserCreate'
import UserEdit from './containers/UserEdit'
import { PrivateRoute, PublicRoute } from './customRoutes/ProtectedRoutes'
import Hoc from './hoc/hoc'

const BaseRouter = () => (
  <Hoc>
    <Switch>
      <PrivateRoute exact path='/' component={InboundList} />
      <PrivateRoute exact path='/reports' component={Reports} />
      <PrivateRoute exact path='/inbounds' component={InboundList} />
      <PrivateRoute exact path='/inbound/create' component={AptCreate} />
      <PrivateRoute exact path='/inbound/edit/:id' component={AptEdit} />
      <PrivateRoute exact path='/inbound/view/:id' component={AptView} />
      <PrivateRoute exact path='/outbounds' component={OutboundList} />
      <PrivateRoute exact path='/outbound/create' component={OutboundCreate} />
      <PrivateRoute exact path='/outbound/edit/:id' component={OutboundEdit} />
      <PrivateRoute exact path='/outbound/view/:id' component={OutboundView} />
      <PrivateRoute exact path='/fba-removals' component={FBARemovalsList} />
      <PrivateRoute exact path='/fba-removals/create' component={FBARemovalCreate} />
      <PrivateRoute exact path='/fba-removals/edit/:id' component={FBARemovalEdit} />
      <PrivateRoute exact path='/fba-removals/view/:id' component={FBARemovalView} />
      <PrivateRoute exact path='/fba-removals/receipts/create' component={FBARemovalReceiptCreate} />
      <PrivateRoute exact path='/fba-removals/edit/:fbaRemovalId/receipts/:id' component={FBARemovalReceiptEdit} />
      <PrivateRoute exact path='/customers' component={CustomerPage} />
      <PrivateRoute exact path='/customer/create' component={CustCreate} />
      <PrivateRoute exact path='/customer/edit/:id' component={CustEdit} />
      <PrivateRoute exact path='/customer/view/:id' component={CustView} />
      <PrivateRoute exact path='/users' component={Dashboard} />
      <PrivateRoute exact path='/users/create' component={UserCreate} />
      <PrivateRoute exact path='/user/edit/:id' component={UserEdit} />
      <PublicRoute exact path='/login' component={Login} />
      <PublicRoute exact path='/reset-password' component={ResetPassword} />
      <PublicRoute exact path='/reset-password/:uid/:token' component={ResetPasswordConfirm} />
      <Route component={NotFound} />
    </Switch>
  </Hoc>
)

export default BaseRouter
