import { Button, Col, Form, Row } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Grid, Header, Segment } from 'semantic-ui-react'
import { backendUrl } from '../config'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 6,
      offset: 8,
    },
  },
}

const OutboundEdit = () => {
  const [data, setData] = useState({})
  const [fileList, setFileList] = useState([])
  const [form] = Form.useForm()
  const history = useHistory()
  const params = useParams()

  useEffect(() => {
    axios
      .get(`${backendUrl}/outbounds/${params.id}/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        if (res.status === 200) {
          setData(res.data)
          form.resetFields()
        }
      })
  }, [])

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file])
      return false
    },
  }
  if (data['delivery'] && data['delivery_name']) {
    props['defaultFileList'] = [
      {
        uid: '1',
        name: data['delivery_name'],
        status: 'done',
        url: data['delivery'],
      },
    ]
  }

  return (
    <Grid textAlign='center' style={{ minHeight: '100vh', height: '100%', marginTop: 'unset' }}>
      <Grid.Column width={15} className='page-inner-style'>
        <Segment basic>
          <Header className='page-header'>
            <span>Edit Outbound</span>
          </Header>
          <Form {...formItemLayout} form={form} name='apt_create' scrollToFirstError>
            <Form.Item name='fba_id' label='FBA ID'>
              {data['fba_id']}
            </Form.Item>

            <Form.Item name='arn_number' label='ARN Number'>
              {data['arn_number']}
            </Form.Item>

            <Form.Item name='pick_done' label='Pick Done'>
              {data['pick_done']}
            </Form.Item>

            <Form.Item name='pick_up' label='Pick Up'>
              {data['pick_up']}
            </Form.Item>

            <Form.Item name='customer' label='Customer'>
              {data['customer']}
            </Form.Item>

            <Form.Item name='carrier' label='Carrier'>
              {data['carrier']}
            </Form.Item>

            <Form.Item name='pallets' label='Number of pallets'>
              {data['pallets']}
            </Form.Item>

            <Form.Item name='status' label='Status'>
              {data['status']}
            </Form.Item>

            <Form.Item name='notes' label='Notes'>
              {data['notes']}
            </Form.Item>

            <Form.Item name='delivery' label='Proof of Delivery'>
              <a href={data['delivery']}>{data['delivery_name']}</a>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Row gutter={12}>
                <Col className='gutter-row' span={8}>
                  <Button block type='primary' onClick={() => history.push('/outbounds')}>
                    Back
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default OutboundEdit
