import { Button, Col, Form, Input, message, Radio, Row } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Grid, Header, Segment } from 'semantic-ui-react'
import { backendUrl } from '../config'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 6,
      offset: 8,
    },
  },
}

const CustEdit = () => {
  const [data, setData] = useState({})
  const [status, setStatus] = useState('active')
  const [form] = Form.useForm()
  const history = useHistory()
  const params = useParams()

  useEffect(() => {
    axios
      .get(`${backendUrl}/customer/${params.id}/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        if (res.status === 200) {
          setData(res.data)
          setStatus(res.data['status'])
          form.resetFields()
        }
      })
  }, [])

  const onFinish = (values) => {
    const data = {
      name: values['name'],
      username: values['username'],
      operations_email: values['operations_email'],
      billing_email: values['billing_email'],
      manager_email: values['manager_email'],
      external_id: values['external_id'],
      status: status,
    }

    axios
      .put(`${backendUrl}/customer/${params.id}/`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        form.resetFields()
        message.success('Customer data updated.')
        history.push('/customers')
      })
      .catch((error) => {
        console.log(error.response)
      })
  }

  return (
    <Grid textAlign='center' style={{ minHeight: '100vh', height: '100%', marginTop: 'unset' }}>
      <Grid.Column width={15} className='page-inner-style'>
        <Segment basic>
          <Header className='page-header'>
            <span>Edit Customer</span>
          </Header>
          <Form
            {...formItemLayout}
            form={form}
            name='cust_edit'
            onFinish={onFinish}
            initialValues={{
              name: data['name'],
              username: data['user'] && data['user']['username'],
              operations_email: data['operations_email'],
              billing_email: data['billing_email'],
              manager_email: data['manager_email'],
              external_id: data['external_id'],
              status: data['status'],
            }}>
            <Form.Item
              name='name'
              label='Name'
              rules={[
                {
                  required: true,
                  message: 'Please input Name',
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item
              name='username'
              label='Username'
              rules={[
                {
                  required: true,
                  message: 'Please input username',
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item
              name='operations_email'
              label='Operations Email'
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: 'Please input valid email address',
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item
              name='billing_email'
              label='Billing Email'
              rules={[
                {
                  type: 'email',
                  message: 'Please input valid email address',
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item
              name='manager_email'
              label='Manager Email'
              rules={[
                {
                  type: 'email',
                  message: 'Please input valid email address',
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item
              name='external_id'
              label='3PL ID'
              rules={[
                {
                  len: 4,
                  message: '3PL ID must be exactly 4 characters',
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item name='status' label='Status'>
              <Radio.Group style={{ width: '100%' }} onChange={(e) => setStatus(e.target.value)}>
                <Row>
                  <Col span={10} offset={1}>
                    <Radio value={'active'}>Active</Radio>
                  </Col>
                  <Col span={12}>
                    <Radio value={'inactive'}>Inactive</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Row gutter={12}>
                <Col className='gutter-row' span={16}>
                  <Button block type='primary' htmlType='submit'>
                    Update
                  </Button>
                </Col>
                <Col className='gutter-row' span={8}>
                  <Button block type='text' onClick={() => history.push('/customers')}>
                    Back
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default CustEdit
