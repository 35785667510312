import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Divider } from 'antd'
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react'
import { GoogleLogin } from 'react-google-login';
import { authLogin, authGoogle } from '../store/actions/auth'
import { GOOGLE_CLIENT_ID } from '../config'

const LoginForm = (props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const history = useHistory()

  const handleChange = (e) => {
    if (e.target.name === 'email') setEmail(e.target.value)
    if (e.target.name === 'password') setPassword(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    props.login(email, password)
  }

  const handleForgot = (e) => {
    e.preventDefault()
    history.push('/reset-password')
  }

  const { error, loading } = props
  return (
    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h2' color='blue' textAlign='center'>
          Log-in to your account
        </Header>

        <React.Fragment>
          <Segment stacked>
            <Form size='large' onSubmit={handleSubmit}>
              <Form.Input
                onChange={handleChange}
                value={email}
                name='email'
                fluid
                icon='mail'
                iconPosition='left'
                placeholder='Email'
              />
              <Form.Input
                onChange={handleChange}
                fluid
                value={password}
                name='password'
                icon='lock'
                iconPosition='left'
                placeholder='Password'
                type='password'
              />
              {error && (
                <p style={{ color: 'red' }}>
                  {error.non_field_errors ? error.non_field_errors[0] : 'Please input your email and password'}
                </p>
              )}
              <Button color='blue' fluid size='large' loading={loading} disabled={loading}>
                Login
              </Button>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', fontSize: '12px' }}>
                <a href='/reset-password' onClick={handleForgot}>
                  Forgot Password?
                </a>
              </div>
            </Form>
            <Divider>OR</Divider>
            <GoogleLogin
              clientId={GOOGLE_CLIENT_ID}
              onSuccess={props.authGoogle}
              onFailure={props.authGoogle}
              cookiePolicy={'single_host_origin'}
            >
            </GoogleLogin>
          </Segment>
        </React.Fragment>
      </Grid.Column>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(authLogin(email, password)),
    authGoogle: (response) => dispatch(authGoogle(response)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
