import TextField from '@material-ui/core/TextField'
import { Alert, Button, Col, Form, Input, InputNumber, message, Row, Select } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Header, Segment } from 'semantic-ui-react'
import { backendUrl } from '../config'

const mainFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 6,
      offset: 8,
    },
  },
}

const FBARemovalReceiptCreate = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const urlParams = new URLSearchParams(history.location.search)
  const [fbaRemovals, setFBARemovals] = useState([])
  const [skus, setSKUs] = useState([])
  const [invalidQuantities, setInvalidQuantities] = useState(false)

  useEffect(() => {
    axios
      .get(`${backendUrl}/fba-removals/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        setFBARemovals(res.data)
        const fbaRemovalId = parseInt(urlParams.get('fba_removal'))
        if (fbaRemovalId) {
          const fbaRemoval = res.data.find(({ id }) => id === fbaRemovalId)
          if (fbaRemoval) {
            form.setFieldsValue({ fba_removal: fbaRemovalId })
            setSKUs(fbaRemoval.skus)
          }
        }
      })
  }, [])

  const onFinish = (values) => {
    const fbaRemoval = fbaRemovals.find(({ id }) => id === values.fba_removal)
    fbaRemoval.receipts.push(values)
    axios
      .put(`${backendUrl}/fba-removals/${fbaRemoval.id}/`, fbaRemoval, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        form.resetFields()
        message.success('New FBA removal receipt created.')
        history.push(`/fba-removals/view/${fbaRemoval.id}`)
      })
      .catch((error) => {
        console.log(error.response)
      })
  }

  const handleValuesChange = (changedValues, allValues) => {
    if (changedValues.fba_removal) {
      const fbaRemoval = fbaRemovals.find(({ id }) => id === changedValues.fba_removal)
      setSKUs(fbaRemoval.skus)
      form.setFieldsValue({ sku: null })
    }
    if (form.isFieldTouched('stated_quantity')) {
      const quantity_missing = Math.max(
        0,
        (allValues.stated_quantity || 0) - (allValues.quantity_received || 0) - (allValues.quantity_damaged || 0),
      )
      if (!form.isFieldTouched('quantity_missing')) {
        form.setFields([
          {
            name: 'quantity_missing',
            value: quantity_missing,
          },
        ])
      }
      setInvalidQuantities(
        allValues.stated_quantity !== allValues.quantity_received + allValues.quantity_damaged + quantity_missing,
      )
    }
  }

  return (
    <Grid textAlign='center' style={{ minHeight: '100vh', height: '100%', marginTop: 'unset' }}>
      <Grid.Column width={15} className='page-inner-style'>
        <Segment basic>
          <Header className='page-header'>
            <span>New FBA removal receipt</span>
          </Header>
          <Form
            form={form}
            name='fba_removal_receipt_create'
            onValuesChange={handleValuesChange}
            onFinish={onFinish}
            scrollToFirstError>
            <Form.Item name='fba_removal' label='FBA Removal' rules={[{ required: true }]} {...mainFormItemLayout}>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder='Select an FBA removal'
                options={fbaRemovals.map((fbaRemoval) => ({
                  value: fbaRemoval.id,
                  label: fbaRemoval.reference,
                }))}></Select>
            </Form.Item>

            <Form.Item name='sku' label='SKU' rules={[{ required: true }]} {...mainFormItemLayout}>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder='Select a SKU'
                options={skus.map((sku) => ({ value: sku.id, label: sku.sku }))}
              />
            </Form.Item>

            <Form.Item name='received_date' label='Received Date' rules={[{ required: true }]} {...mainFormItemLayout}>
              <TextField
                type='date'
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Form.Item>

            <Form.Item name='tracking' label='Tracking' rules={[{ required: true }]} {...mainFormItemLayout}>
              <Input />
            </Form.Item>

            <Form.Item name='amz_barcode' label='AMZ Barcode' rules={[{ required: true }]} {...mainFormItemLayout}>
              <Input />
            </Form.Item>

            <Form.Item
              name='stated_quantity'
              label='Stated QTY'
              rules={[{ required: true, type: 'integer', min: 0 }]}
              {...mainFormItemLayout}>
              <InputNumber />
            </Form.Item>

            <Form.Item
              name='quantity_received'
              label='QTY Received'
              rules={[{ required: true, type: 'integer', min: 0 }]}
              {...mainFormItemLayout}>
              <InputNumber />
            </Form.Item>

            <Form.Item
              name='quantity_damaged'
              label='QTY Damaged'
              rules={[{ required: true, type: 'integer', min: 0 }]}
              {...mainFormItemLayout}>
              <InputNumber />
            </Form.Item>

            <Form.Item
              name='quantity_missing'
              label='QTY Missing'
              rules={[{ required: true, type: 'integer', min: 0 }]}
              {...mainFormItemLayout}>
              <InputNumber />
            </Form.Item>

            {invalidQuantities && (
              <Form.Item {...tailFormItemLayout}>
                <Alert type='warning' message="Please review quantities because they don't add up." />
              </Form.Item>
            )}

            <Form.Item {...tailFormItemLayout}>
              <Row gutter={12}>
                <Col className='gutter-row' span={16}>
                  <Button block type='primary' htmlType='submit'>
                    Create
                  </Button>
                </Col>
                <Col className='gutter-row' span={8}>
                  <Button block type='text' onClick={() => history.push('/fba-removals')}>
                    Back
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default FBARemovalReceiptCreate
