import React from 'react'
import zxcvbn from 'zxcvbn'
import './PasswordStrMeter.css'

const PasswordStrengthMeter = ({password, setValidated}) => {
  const score = zxcvbn(password).score
  let meterClassName = ''
  let message = ''

  if (password.length < 8) {
    setValidated(false)
    meterClassName = 'Weak'
    message = 'Password is short'
  } else {
    switch (score) {
      case 0:
        setValidated(false)
        meterClassName = 'Weak'
        message = 'Password is weak'
        break
      case 1:
        setValidated(false)
        meterClassName = 'Weak'
        message = 'Password is weak'
        break
      case 2:
        setValidated(true)
        meterClassName = 'Fair'
        message = 'Password is fair'
        break
      case 3:
        setValidated(true)
        meterClassName = 'Good'
        message = 'Password is good'
        break
      case 4:
        setValidated(true)
        meterClassName = 'Strong'
        message = 'Password is strong'
        break
      default:
        setValidated(false)
        meterClassName = 'Weak'
        message = 'Password is weak'
    }
  }

  return (
    <div className='password-strength-meter'>
      <progress className={`password-strength-meter-progress strength-${meterClassName}`} value={score} max='4' />
      <br />
      <label className='password-strength-meter-label'>
        {password && (
          <>
            <strong>Password strength:</strong> {message}
          </>
        )}
      </label>
    </div>
  )
}

export default PasswordStrengthMeter
