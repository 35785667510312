import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Button, DatePicker, Form, Input, message, Modal, Popconfirm, Select, Space, Table, Tooltip } from 'antd'
import axios from 'axios'
import { ExportToCsv } from 'export-to-csv'
import _ from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Grid, Header, Segment } from 'semantic-ui-react'
import { backendUrl } from '../config'

const { TextArea } = Input
const { Search } = Input
const { Option } = Select

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
}

const InboundList = () => {
  const [data, setData] = useState([])
  const [filtered, setFiltered] = useState([])
  const [org, setOrg] = useState([])
  const [mode, setMode] = useState('Today')
  const [visible, setVisible] = useState(false)
  const [curapt, setCurapt] = useState({})
  const [keyword, setKeyword] = useState('')
  const [form] = Form.useForm()
  const [cancelVisible, setCancelVisible] = useState(false)
  const [cancelForm] = Form.useForm()
  const [dateRange, setDateRange] = useState([])
  const [location, setLocation] = useState(null);

  const history = useHistory()
  let rowClass = 0

  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  const getDow = (day) => {
    return weekdays[day]
  }

  useEffect(() => {
    axios
      .get(`${backendUrl}/inbounds/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        const apts = res.data.map((rec) => {
          return {
            ...rec,
            key: rec.id,
            day: getDow(moment(rec.date).weekday()),
            load: rec.load == null ? '' : rec.load,
            po: rec.po == null ? '' : rec.po,
            container_size: rec.container_size == null ? '' : rec.container_size,
            door_number: rec.door_number == null ? '' : rec.door_number,
            notes: rec.notes == null ? '' : rec.notes,
          }
        })
        setData(apts)

        // const curTime = moment().tz('America/Los_Angeles').format('M/D/Y');
        // const filt = apts.filter(rec => moment(rec.date) >= moment(curTime));
        const curTime = moment().format('Y-MM-DD')
        const filt = apts.filter((rec) => rec.date === curTime && rec.status === 'Open')
        setFiltered(filt)

        const orgd = res.data.map((rec) => {
          rec.loading = rec.loading === 'drop_off' ? 'Drop Off' : 'Live Unload'
          rec.load_type = rec.load_type === 'palletized' ? 'Palletized' : 'Floor Loaded'
          return rec
        })
        setOrg(orgd)
      })
  }, [])

  useEffect(() => form.resetFields(), [curapt])
  useEffect(() => filterApt(), [keyword, dateRange])

  const handleDelete = (id) => {
    axios
      .delete(`${backendUrl}/inbound/${id}/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      .then((res) => {
        if (res.status === 204) {
          message.success('Inbound deleted.')
          const newData = data.filter((item) => item.id !== id)
          setData(newData)
          const newFilt = filtered.filter((item) => item.id !== id)
          setFiltered(newFilt)
          let newOrg = org.filter((item) => item.id !== id)
          setOrg(newOrg)
        }
      })
  }

  const options = {
    filename: 'inbounds',
    showLabels: true,
    headers: [
      'Date',
      'Start Time',
      'End Time',
      'Customer',
      'PO #',
      'Carrier',
      'Container Number',
      'Container Size',
      'Load #',
      'Loading',
      'Load Type',
      'Door Number',
      'Number of Pallets',
      'Notes',
    ],
  }

  const exportCSV = () => {
    if (org.length > 0) {
      let csvdata = []
      for (let i = 0; i < org.length; i++) {
        csvdata.push({
          date: org[i]['date'],
          start_time: org[i]['start_time'],
          end_time: org[i]['end_time'],
          customer: org[i]['customer'],
          po: org[i]['po'],
          carrier: org[i]['carrier'],
          container: org[i]['container'],
          container_size: org[i]['container_size'],
          load: org[i]['load'],
          loading: org[i]['loading'],
          load_type: org[i]['load_type'],
          door_number: org[i]['door_number'],
          pallets: org[i]['pallets'],
          notes: org[i]['notes'],
        })
      }
      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(csvdata)
    } else {
      message.warning('No data to export.')
    }
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => <Link to={`/inbound/view/${record.id}`}>{text}</Link>,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) => moment(a.date || undefined) - moment(b.date || undefined),
        multiple: 3,
      },
      render: (text) => {
        text = moment(text).format('MM/DD/YYYY')
        return <span>{text}</span>
      },
    },
    {
      title: 'Day of the Week',
      dataIndex: 'day',
      key: 'day',
      sorter: (a, b) => (a.day > b.day ? 1 : -1),
    },
    {
      title: 'Start Time',
      dataIndex: 'start_time',
      key: 'start_time',
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) => moment(a.start_time || undefined, 'HH:mm') - moment(b.start_time || undefined, 'HH:mm'),
        multiple: 2,
      },
      render: (text) => {
        text = moment(text, 'HH:mm').format('h:mm A')
        return <span>{text}</span>
      },
    },
    {
      title: 'End Time',
      dataIndex: 'end_time',
      key: 'end_time',
      sorter: {
        compare: (a, b) => moment(a.end_time || undefined, 'HH:mm') - moment(b.end_time || undefined, 'HH:mm'),
        multiple: 1,
      },
      render: (text) => {
        text = moment(text, 'HH:mm').format('h:mm A')
        return <span>{text}</span>
      },
    },
    {
      title: 'Customer',
      key: 'customer',
      dataIndex: 'customer',
      sorter: (a, b) => (a.customer > b.customer ? 1 : -1),
    },
    {
      title: 'Carrier',
      key: 'carrier',
      dataIndex: 'carrier',
      sorter: (a, b) => (a.carrier > b.carrier ? 1 : -1),
    },
    {
      title: 'Container Size',
      key: 'container_size',
      dataIndex: 'container_size',
      sorter: (a, b) => (a.container_size > b.container_size ? 1 : -1),
    },
    {
      title: 'Container Number',
      key: 'container',
      dataIndex: 'container',
      sorter: (a, b) => (a.container > b.container ? 1 : -1),
    },
    {
      title: 'Load #',
      key: 'load',
      dataIndex: 'load',
      sorter: (a, b) => (a.load > b.load ? 1 : -1),
    },
    {
      title: 'Loading',
      key: 'loading',
      dataIndex: 'loading',
      sorter: (a, b) => (a.loading > b.loading ? 1 : -1),
      render: (text) => {
        if (text === 'drop_off') {
          return <span>Drop Off</span>
        } else if (text === 'live_unload') {
          return <span>Live Unload</span>
        }
      },
    },
    {
      title: 'Load Type',
      key: 'load_type',
      dataIndex: 'load_type',
      sorter: (a, b) => (a.load_type > b.load_type ? 1 : -1),
      render: (text) => {
        if (text === 'palletized') {
          return <span>Palletized</span>
        } else if (text === 'floor_loaded') {
          return <span>Floor Loaded</span>
        }
      },
    },
    {
      title: 'Charges',
      key: 'charges',
      dataIndex: 'charges',
      sorter: (a, b) => (a.charges > b.charges ? 1 : -1),
      render: (text) => <span>${text}</span>,
    },
    {
      title: 'Location',
      key: 'location',
      dataIndex: 'location',
      sorter: (a, b) => (a.location > b.location ? 1 : -1),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size='middle'>
          {record.status === 'Open' && (
            <a
              onClick={() => {
                setCurapt(record)
                setVisible(true)
              }}>
              <Tooltip title='Close'>
                <CheckCircleOutlined className={'action-icon'} />
              </Tooltip>
            </a>
          )}
          {record.status === 'Open' && (
            <a
              onClick={() => {
                setCurapt(record)
                setCancelVisible(true)
              }}>
              <Tooltip title='Cancel'>
                <CloseCircleOutlined className={'action-icon'} />
              </Tooltip>
            </a>
          )}
          <a onClick={() => history.push('/inbound/edit/' + record.key)}>
            <Tooltip title='Edit'>
              <EditOutlined className={'action-icon'} />
            </Tooltip>
          </a>
          <Popconfirm
            title='Are you sure delete this?'
            onConfirm={() => handleDelete(record.key)}
            okText='Yes'
            cancelText='No'>
            <a>
              <Tooltip title='Delete'>
                <DeleteOutlined className={'action-icon'} />
              </Tooltip>
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const palletColumn = {
    title: 'Number of Pallets',
    key: 'pallets',
    dataIndex: 'pallets',
    sorter: (a, b) => (a.pallets > b.pallets ? 1 : -1),
  }

  const onChangeMode = (value) => {
    // const curTime = moment().tz('America/Los_Angeles').format('Y-MM-DD');
    const curTime = moment().format('Y-MM-DD')
    let apts
    setMode(value)
    if (value === 'All') {
      apts = data.filter((rec) => rec.status !== '')
    } else {
      apts = data.filter((rec) => {
        return value === 'Today' ? rec.date === curTime && rec.status === 'Open' : rec.status === value
      })
    }
    if (location) {
      apts = apts.filter((rec) => rec.location === location)
    }
    const newFilt = apts.filter((rec) => {
      if (dateRange.length !== 0 && dateRange[1] !== '') {
        return (
          (rec.customer.toLowerCase().indexOf(keyword) > -1 ||
            rec.container.toLowerCase().indexOf(keyword) > -1 ||
            rec.po.toLowerCase().indexOf(keyword) > -1 ||
            rec.notes.toLowerCase().indexOf(keyword) > -1) &&
          moment(rec.date).isSameOrAfter(dateRange[0]) &&
          moment(dateRange[1]).isSameOrAfter(rec.date)
        )
      } else {
        return (
          rec.customer.toLowerCase().indexOf(keyword) > -1 ||
          rec.container.toLowerCase().indexOf(keyword) > -1 ||
          rec.po.toLowerCase().indexOf(keyword) > -1 ||
          rec.notes.toLowerCase().indexOf(keyword) > -1
        )
      }
    })
    setFiltered(newFilt)
  }

  const onChangeLocation = (value) => {
    // const curTime = moment().tz('America/Los_Angeles').format('Y-MM-DD');
    const curTime = moment().format('Y-MM-DD')
    let apts
    setLocation(value)
    if (mode === 'All') {
      apts = data.filter((rec) => rec.status !== '')
    } else {
      apts = data.filter((rec) => {
        return mode === 'Today' ? rec.date === curTime && rec.status === 'Open' : rec.status === mode
      })
    }
    if (value) {
      apts = apts.filter((rec) => rec.location === value)
    }
    const newFilt = apts.filter((rec) => {
      if (dateRange.length !== 0 && dateRange[1] !== '') {
        return (
          (rec.customer.toLowerCase().indexOf(keyword) > -1 ||
            rec.container.toLowerCase().indexOf(keyword) > -1 ||
            rec.po.toLowerCase().indexOf(keyword) > -1 ||
            rec.notes.toLowerCase().indexOf(keyword) > -1) &&
          moment(rec.date).isSameOrAfter(dateRange[0]) &&
          moment(dateRange[1]).isSameOrAfter(rec.date)
        )
      } else {
        return (
          rec.customer.toLowerCase().indexOf(keyword) > -1 ||
          rec.container.toLowerCase().indexOf(keyword) > -1 ||
          rec.po.toLowerCase().indexOf(keyword) > -1 ||
          rec.notes.toLowerCase().indexOf(keyword) > -1
        )
      }
    })
    setFiltered(newFilt)
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields()
        setVisible(false)
        values['status'] = 'Closed'
        axios
          .patch(`${backendUrl}/inbound/${curapt['id']}/`, values, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          })
          .then((res) => {
            const newData = data.map((rec) => {
              if (rec.id === curapt.id) rec.status = 'Closed'
              return rec
            })
            const newFilt = newData.filter((rec) => rec.status === 'Open')
            setData(newData)
            setFiltered(newFilt)
            message.success('Inbound closed.')
          })
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }

  const handleCancelOk = () => {
    cancelForm
      .validateFields()
      .then((values) => {
        cancelForm.resetFields()
        setCancelVisible(false)
        values['status'] = 'Canceled'
        axios
          .patch(`${backendUrl}/inbound/${curapt['id']}/`, values, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          })
          .then((res) => {
            const newData = data.map((rec) => {
              if (rec.id === curapt.id) rec.status = 'Canceled'
              return rec
            })
            const newFilt = newData.filter((rec) => rec.status === 'Open')
            setData(newData)
            setFiltered(newFilt)
            message.success('Inbound canceled.')
          })
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }

  const filterApt = () => {
    if (data.length > 0) {
      const debouncedSave = _.debounce(() => {
        // const curTime = moment().tz('America/Los_Angeles').format('Y-MM-DD');
        const curTime = moment().format('Y-MM-DD')
        const value = keyword.toLowerCase()
        const newFilt = data.filter((rec) => {
          if (mode === 'All') {
            if (dateRange.length !== 0 && dateRange[1] !== '') {
              return (
                (rec.customer.toLowerCase().indexOf(value) > -1 ||
                  rec.container.toLowerCase().indexOf(value) > -1 ||
                  rec.po.toLowerCase().indexOf(value) > -1 ||
                  rec.notes.toLowerCase().indexOf(value) > -1) &&
                moment(rec.date).isSameOrAfter(dateRange[0]) &&
                moment(dateRange[1]).isSameOrAfter(rec.date)
              )
            } else {
              return (
                rec.customer.toLowerCase().indexOf(value) > -1 ||
                rec.container.toLowerCase().indexOf(value) > -1 ||
                rec.po.toLowerCase().indexOf(value) > -1 ||
                rec.notes.toLowerCase().indexOf(value) > -1
              )
            }
          } else {
            if (dateRange.length !== 0 && dateRange[1] !== '') {
              return (
                (mode === 'Today' ? rec.date === curTime : rec.status === mode) &&
                (rec.customer.toLowerCase().indexOf(value) > -1 ||
                  rec.container.toLowerCase().indexOf(value) > -1 ||
                  rec.po.toLowerCase().indexOf(value) > -1 ||
                  rec.notes.toLowerCase().indexOf(value) > -1) &&
                moment(rec.date).isSameOrAfter(dateRange[0]) &&
                moment(dateRange[1]).isSameOrAfter(rec.date)
              )
            } else {
              return (
                (mode === 'Today' ? rec.date === curTime : rec.status === mode) &&
                (rec.customer.toLowerCase().indexOf(value) > -1 ||
                  rec.container.toLowerCase().indexOf(value) > -1 ||
                  rec.po.toLowerCase().indexOf(value) > -1 ||
                  rec.notes.toLowerCase().indexOf(value) > -1)
              )
            }
          }
        })
        setFiltered(newFilt)
      }, 1000)
      debouncedSave()
    }
  }

  return (
    <Grid textAlign='center' style={{ minHeight: '100vh', height: '100%', marginTop: 'unset' }}>
      <Grid.Column width={15} className='page-inner-style'>
        <Segment basic>
          <Header className='page-header'>
            <span>Inbounds</span>
          </Header>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <div>
              <Select defaultValue={mode} style={{ width: 120 }} onChange={onChangeMode}>
                <Option value='Today'>Today</Option>
                <Option value='Open'>Open</Option>
                <Option value='Closed'>Closed</Option>
                <Option value='Canceled'>Canceled</Option>
                <Option value='All'>All</Option>
              </Select>
              <Select
                defaultValue={location}
                style={{ width: 160, marginLeft: '10px' }}
                allowClear
                onChange={onChangeLocation}
                placeholder='Search by location'
              >
                <Option value='Ontario'>Ontario</Option>
                <Option value='Reidsville'>Reidsville</Option>
              </Select>
              <Search
                style={{ width: 200, marginLeft: '10px' }}
                placeholder='Search by content'
                onChange={(e) => setKeyword(e.target.value)}
              />
              <DatePicker.RangePicker
                style={{ width: 250, marginLeft: '10px' }}
                disabled={mode === 'Today'}
                onChange={(date, dateString) => setDateRange(dateString)}
              />
            </div>
            <div>
              <Button style={{ marginRight: '10px' }} onClick={exportCSV} icon={<ExportOutlined />}>
                Export CSV
              </Button>
              <Button type='primary' onClick={() => history.push('/inbound/create')} icon={<PlusOutlined />}>
                New Inbound
              </Button>
            </div>
          </div>
          <Table
            className={'page-table'}
            size='middle'
            columns={
              mode === 'Open'
                ? columns
                : [...columns.slice(0, columns.length - 1), palletColumn, columns[columns.length - 1]]
            }
            dataSource={filtered}
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 50,
            }}
            showSorterTooltip={false}
            rowClassName={(record, index) => {
              if (filtered[index - 1] && filtered[index - 1].date !== filtered[index].date) {
                rowClass = (rowClass + 1) % 2
              }
              return 'apt-alter-row-' + rowClass
            }}
          />
        </Segment>
      </Grid.Column>
      <Modal
        title='Close Inbound'
        visible={visible}
        onOk={() => handleOk()}
        onCancel={() => setVisible(false)}
        width={700}>
        <Form
          {...formItemLayout}
          form={form}
          name='apt_close'
          initialValues={{
            notes: curapt['notes'],
          }}>
          <Form.Item label='Start Time'>{moment(curapt['start_time'], 'HH:mm').format('h:mm A')}</Form.Item>
          <Form.Item label='End Time'>{moment(curapt['end_time'], 'HH:mm').format('h:mm A')}</Form.Item>
          <Form.Item
            name='pallets'
            label='Number of Pallets'
            rules={[
              {
                required: true,
                message: 'Please input Number of Pallets',
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item name='notes' label='Notes'>
            <TextArea rows={3} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title='Cancel Inbound'
        visible={cancelVisible}
        onOk={() => handleCancelOk()}
        onCancel={() => setCancelVisible(false)}
        width={500}>
        <Form {...formItemLayout} form={cancelForm} name='apt_cancel'>
          <Form.Item
            name='charges'
            label='Charges'
            rules={[
              {
                required: true,
                message: 'Please input charges amount',
              },
            ]}>
            <Input prefix='$' suffix='USD' />
          </Form.Item>
        </Form>
      </Modal>
    </Grid>
  )
}
export default InboundList
