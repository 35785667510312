import React from 'react'
import {Redirect, Route} from 'react-router-dom'
// import _get from 'lodash/get';
// import qs from 'query-string';

// const getQueryParam = (search, query) => {
//   const parsed = qs.parse(search);
//   return _get(parsed, query, '');
// }

const checkAuth = () => {
  const token = localStorage.getItem('token')
  if (!token) {
    return false
  }
  return true
}

const PrivateRoute = ({component: Component, ...props}) => {
  // const location = useLocation();
  if (!checkAuth()) {
    const loginPath = '/login'
    // const redirectPath = location
    // ? `${loginPath}?redirect=${location.pathname}${location.search}`
    // : loginPath;
    return <Redirect to={loginPath} />
  }

  return (
    <Route {...props}>
      <Component />
    </Route>
  )
}

const PublicRoute = ({component: Component, ...props}) => {
  let redirectPath = '/'
  // if (props.location) {
  //   redirectPath = getQueryParam(props.location.search, 'redirect')
  // }

  if (checkAuth()) {
    return <Redirect to={redirectPath} />
  }

  return (
    <Route {...props}>
      <Component />
    </Route>
  )
}

export {PrivateRoute, PublicRoute}
